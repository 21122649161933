<template>
  <div class="pic-show">
    <img :src="showUrl" @click="visible = true" style="cursor: pointer" />

    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="[largeUrl]"
      :index="0"
      @hide="visible = false"
    ></vue-easy-lightbox>
    <!-- <div
      :class="bottom ? 'large-pic-bottom' : 'large-pic'"
      :style="`width: ${largeWidth}px;`"
    >
      <img :src="largeUrl" style="width: 100%;" />
    </div> -->
  </div>
</template>

<script>
import { IMG_LINK } from '@/views/service/constant.js'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  // props: ['showUrl', 'largeUrl'],
  props: {
    showUrl: String,
    largeUrl: String,
    largeWidth: {
      default: 300
    },
    // 图片向上还是向下
    bottom: {
      default: false
    }
  },
  components: {
    VueEasyLightbox
  },
  mixins: [],
  data() {
    return {
      visible: false
    }
  },
  watch: {},
  computed: {
    imgLink() {
      return IMG_LINK
    }
  },
  mounted() {},
  created() {},
  filters: {},
  methods: {}
}
</script>
